import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV
  })
}

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path={'/'}
          component={() => <div>Sausage Sizzle Merchant Web App</div>}
          exact
        />
        <Route component={() => <div>NotFound</div>} />
      </Switch>
    </Router>
  )
}

export default App
